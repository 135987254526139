import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function ThirdPartyRateCalculator() {

    const [post, setPost] = React.useState(null);
    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            var isPurchaseAdmin = jsonObject2.userRoles != null && (jsonObject2.userRoles == 'administrator' || jsonObject2.userRoles == 'purchaseAdministrator');

            const state = {
                costSheetId: "",
                composition: "",
                activeApis:"",
                category: "",
                itemType: "",
                quantity: "",
                packing: "",
                apisMap: new Map()
            };

            const handleChangeComposition = event => {

                var inputcomp = document.getElementById("composition");
                var inputcategory = document.getElementById("category");
                var inputpacking = document.getElementById("packing");
                var inputitemType = document.getElementById("itemType");
                var inputquantity = document.getElementById("quantity");

                state.costSheetId = event.value.id;
                state.composition = event.value.composition;
                state.activeApis = event.value.activeApis;
                state.category = event.value.category;
                state.itemType = event.value.itemType;
                state.quantity = event.value.quantity;
                state.packing = event.value.packing;

                inputcategory.value = event.value.category;
                inputitemType.value = event.value.itemType;
                inputcomp.value = event.value.composition;
                inputpacking.value = event.value.packing;
                inputquantity.value = event.value.quantity;

                state.apisMap.clear();

                const myNode = document.getElementById("activeApiInputId");

                while (myNode.firstChild) {
                    myNode.removeChild(myNode.lastChild);
                }

                var url = "https://cascamailsender.azurewebsites.net/getActiveApiNames?activeApis=" + state.activeApis;
                axios.get(url)
                    .then((response) => {
                        response.data.forEach((activeApi) => {

                            var x = document.createElement("div");
                            x.setAttribute("id", activeApi.name + "_flex");
                            x.setAttribute("class", "flex-container");
                            document.getElementById("activeApiInputId").appendChild(x);

                            var y = document.createElement("div");
                            y.setAttribute("id", activeApi.name + "_key");
                            y.setAttribute("class", "flex-child magenta");
                            x.appendChild(y);

                            var z = document.createElement("h4");
                            z.textContent = activeApi.name;
                            y.appendChild(z);

                            var y = document.createElement("div");
                            y.setAttribute("id", activeApi.name + "_value");
                            y.setAttribute("class", "flex-child green");
                            x.appendChild(y);

                            var z = document.createElement("input");
                            z.setAttribute("type", "number");
                            z.setAttribute("step", "any");
                            z.addEventListener('change', function () {
                                state.apisMap.set(activeApi.id, this.value);
                            });
                            y.appendChild(z);
                        });
                    });
            };

            const onUpload = () => {

                const myNode = document.getElementById("finalRateId");

                while (myNode.firstChild) {
                    myNode.removeChild(myNode.lastChild);
                }

                const obj = Object.fromEntries(state.apisMap);
                const serialized = JSON.stringify(obj);

                const options = {
                    method: 'POST',
                    url: 'https://cascamailsender.azurewebsites.net/thirdPartyCalculator',
                    params: { 'costSheetId': state.costSheetId },
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: serialized,
                };

                axios
                    .request(options)
                    .then(function (response) {

                        var x = document.createElement("div");
                        x.setAttribute("id", "rate_flex");
                        x.setAttribute("class", "flex-container");
                        document.getElementById("finalRateId").appendChild(x);

                        var y = document.createElement("div");
                        y.setAttribute("id", "rate_key");
                        y.setAttribute("class", "flex-child magenta");
                        x.appendChild(y);

                        var z = document.createElement("h4");
                        z.textContent = "Final Rate";
                        y.appendChild(z);

                        var y = document.createElement("div");
                        y.setAttribute("id", "rate_value");
                        y.setAttribute("class", "flex-child green");
                        x.appendChild(y);

                        var z = document.createElement("h4");
                        z.textContent = response.data;
                        z.setAttribute("disabled", "true");
                        y.appendChild(z);

                    })
                    .catch(function (error) {
                        console.error(error);
                    });       
            };


            const costSheets = [];

            fetch('https://cascamailsender.azurewebsites.net/allcostsheets', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost(data));

            if (!post) return null;

            var postModified = post.replace(/'/g, '"');
            let jsonData = JSON.parse(JSON.stringify(postModified));
            let jsonObject = JSON.parse(jsonData);
            jsonObject.forEach(object => {
                costSheets.push({ label: object.composition + ", " + object.category + ", " + object.itemType, value: object });
            })

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Calculate Third Party Rate</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Composition Details</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeComposition.bind(this)} options={costSheets} id="costSheet" name="costSheet">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Composition</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="composition" name="composition"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Category</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="category" name="category"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Item Type</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="itemType" name="itemType"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Quantity</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="quantity" name="quantity"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Packing</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="packing" name="packing"></input>
                        </div>

                    </div>


                    <div id="activeApiInputId">

                    </div>                   

                    <button class="button" onClick={onUpload}>CALCULATE</button>

                    <div id="finalRateId">

                    </div>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>


                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>


                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}