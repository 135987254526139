import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { data } from 'jquery';
import background from "../background.jpg";

export function DeleteAccount() {

    const state = {
        username: ""
    };

    const handleSubmit = event => {

        /*const formData = new FormData();

        formData.append("username", state.username);

        axios.post("https://cascamailsender.azurewebsites.net/accountdeletion", formData)
            .then((response) => {
                alert(response.data)
            });*/

        alert("For username: " + state.username + ", account deletion successfully requested!");
    }

    return (
        <div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="Auth">

                
            <h2>Request Account Deletion</h2>

            <div style={{ marginTop: "30vh" }} class="flex-container">

                <div class="auth-child magenta">
                    <h4>Username</h4>
                </div>

                <div class="auth-child green">
                    <input onChange={event => { state.username = event.target.value; }} type="text" id="username" name="username"></input>
                </div>

            </div>

            <div>
                <button onClick={handleSubmit} className="button">DELETE</button>
            </div>
        </div>
    ); 
}