import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function UpdateProductionProcess() {

    const [post, setPost] = React.useState(null);
    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            var isPurchaseAdmin = jsonObject2.userRoles != null && (jsonObject2.userRoles == 'administrator' || jsonObject2.userRoles == 'purchaseAdministrator');

            const state = {
                stage: "",
                updatedBy: jsonObject2.name,
                orderId: "",
                quantity: "",
                packingVariant: "",
                packingType: "",
                updatedAt: null
            };

            const handleChange = event => {
                state.orderId = event.value.orderId;
            };

            const handleChangeStage = event => {
                state.stage = event.value;

                if (document.getElementById("oldStockName") != null) {
                    document.getElementById("oldStockName").remove();
                    document.getElementById("oldStockValue").remove();
                }

                if (state.stage == "EXISTING_OUTER_BOX_STOCK_CHECK" || state.stage == "EXISTING_INNER_BOX_STOCK_CHECK" || state.stage == "EXISTING_FOIL_STOCK_CHECK" || state.stage == "EXISTING_BASE_FOIL_STOCK_CHECK" || state.stage == "EXISTING_LABEL_STOCK_CHECK"
                    || state.stage == "OUTER_BOX_REC" || state.stage == "INNER_BOX_REC" || state.stage == "FOIL_REC" || state.stage == "BASE_FOIL_REC" || state.stage == "LABEL_REC") {

                    var y = document.createElement("div");
                    y.setAttribute("id", "oldStockName");
                    y.setAttribute("class", "flex-child magenta");
                    document.getElementById("packingMaterialStockId").appendChild(y);

                    state.packingType = "EXISTING";
                    if (state.stage == "OUTER_BOX_REC" || state.stage == "INNER_BOX_REC" || state.stage == "FOIL_REC" || state.stage == "BASE_FOIL_REC" || state.stage == "LABEL_REC") {
                        state.packingType = "NEW";
                    }


                    var stockVariant = "Outer Box Quantity";
                    state.packingVariant = "OUTER_BOX";
                    if (state.stage == "EXISTING_INNER_BOX_STOCK_CHECK" || state.stage == "INNER_BOX_REC") {
                        stockVariant = "Inner Box Quantity";
                        state.packingVariant = "INNER_BOX";
                    } else if (state.stage == "EXISTING_FOIL_STOCK_CHECK" || state.stage == "FOIL_REC") {
                        stockVariant = "Foil Quantity (in Kg)";
                        state.packingVariant = "FOIL";
                    } else if (state.stage == "EXISTING_BASE_FOIL_STOCK_CHECK" || state.stage == "BASE_FOIL_REC") {
                        stockVariant = "Base Foil Quantity (in Kg)";
                        state.packingVariant = "BASE_FOIL";
                    } else if (state.stage == "EXISTING_LABEL_STOCK_CHECK" || state.stage == "LABEL_REC") {
                        stockVariant = "Label Quantity";
                        state.packingVariant = "LABEL";
                    }

                    var z = document.createElement("h4");
                    z.textContent = stockVariant;
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "oldStockValue");
                    y.setAttribute("class", "flex-child green");
                    document.getElementById("packingMaterialStockId").appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "number");
                    z.setAttribute("step", "any");
                    z.addEventListener('change', function () {
                        state.quantity = this.value;
                    });
                    y.appendChild(z);
                }

            };

            const onUpload = () => {

                // Create an object of formData
                const formData = new FormData();

                formData.append("orderId", state.orderId);
                formData.append("stage", state.stage);
                formData.append("updatedBy", state.updatedBy);

                if (state.updatedAt != null && state.updatedAt != '') {
                    formData.append("updatedAt", new Date(state.updatedAt));
                }

                axios.post("https://cascamailsender.azurewebsites.net/addproductionprocessstage", formData)
                    .then((response) => {
                        if (response.data == "STAGE_SAVED") {
                            alert("Stage Saved")
                        }
                    });


                if (state.stage == "EXISTING_OUTER_BOX_STOCK_CHECK" || state.stage == "EXISTING_INNER_BOX_STOCK_CHECK" || state.stage == "EXISTING_FOIL_STOCK_CHECK" || state.stage == "EXISTING_BASE_FOIL_STOCK_CHECK" || state.stage == "EXISTING_LABEL_STOCK_CHECK"
                    || state.stage == "OUTER_BOX_REC" || state.stage == "INNER_BOX_REC" || state.stage == "FOIL_REC" || state.stage == "BASE_FOIL_REC" || state.stage == "LABEL_REC") {

                    const formData1 = new FormData();

                    formData1.append("orderId", state.orderId);
                    formData1.append("packingVariant", state.packingVariant);
                    formData1.append("packingType", state.packingType);
                    formData1.append("quantity", state.quantity);
                    formData1.append("updatedBy", state.updatedBy);

                    if (state.updatedAt != null && state.updatedAt != '') {
                        formData1.append("updatedAt", new Date(state.updatedAt));
                    }


                    axios.post("https://cascamailsender.azurewebsites.net/addpackingmaterialstock", formData1)
                        .then((response) => {
                            if (response.data == "PACKING_MATERIAL_STOCK_SAVED") {
                                //alert("Packing Material Stock Saved")
                            }
                        });
                }
            };


            const Orders = [];

            fetch('https://cascamailsender.azurewebsites.net/allproductionorders', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost(data));

            if (!post) return null;

            var postModified = post.replace(/'/g, '"');
            let jsonData = JSON.parse(JSON.stringify(postModified));
            let jsonObject = JSON.parse(jsonData);
            jsonObject.forEach(object => {
                Orders.push({ label: "(" + object.orderId + ") " + object.productName + ", " + object.packing + ", MRP: " + object.mrp, value: object });
            })

            const Stages = [
                { label: "Order Received", value: "ORDER_REC" },
                { label: "Design Approved By Party", value: "DESIGN_APPROVAL_BY_PARTY" },
                { label: "Address Proof Submitted By Party", value: "ADDRESS_PROOF_DOC_SUBMISSION" },
                { label: "Design Approved By Production Team", value: "DESIGN_APPROVAL_BY_CASCA" },
                { label: "Existing Outer Box Stock Check", value: "EXISTING_OUTER_BOX_STOCK_CHECK" },
                { label: "Existing Inner Box Stock Check", value: "EXISTING_INNER_BOX_STOCK_CHECK" },
                { label: "Existing Foil Stock Check", value: "EXISTING_FOIL_STOCK_CHECK" },
                { label: "Existing Base Foil Stock Check", value: "EXISTING_BASE_FOIL_STOCK_CHECK" },
                { label: "Existing Label Stock Check", value: "EXISTING_LABEL_STOCK_CHECK" },
                { label: "Outer Box Sent For Printing", value: "OUTER_BOX_SENT_FOR_PRINTING" },
                { label: "Inner Box Sent For Printing", value: "INNER_BOX_SENT_FOR_PRINTING" },
                { label: "Foil Sent For Printing", value: "FOIL_SENT_FOR_PRINTING" },
                { label: "Base Foil Sent For Printing", value: "BASE_FOIL_SENT_FOR_PRINTING" },
                { label: "Label Sent For Printing", value: "LABEL_SENT_FOR_PRINTING" },
                { label: "Bottle Order Placed", value: "BOTTLE_ORDER_PLACED" },
                { label: "Pump Order Placed", value: "PUMP_ORDER_PLACED" },
                { label: "Order Sent to Production", value: "ORDER_SENT_TO_PRODUCTION" },
                { label: "Outer Box Received", value: "OUTER_BOX_REC" },
                { label: "Inner Box Received", value: "INNER_BOX_REC" },
                { label: "Foil Received", value: "FOIL_REC" },
                { label: "Base Foil Received", value: "BASE_FOIL_REC" },
                { label: "Label Received", value: "LABEL_REC" },
                { label: "Bottle Received", value: "BOTTLE_REC" },
                { label: "Pump Received", value: "PUMP_REC" },
                { label: "Raw Material Order Placed", value: "RAW_MATERIAL_ORDER_PLACED" },
                { label: "Raw Material Received", value: "RAW_MATERIAL_ORDER_REC" },
                { label: "Capsules Filling Completed", value: "CAPSULE_FILLING_COMPLETED" },
                { label: "Granulation Completed", value: "GRANULATION_COMPLETED" },
                { label: "Punching Completed", value: "PUNCHING_COMPLETED" },
                { label: "Coating Completed", value: "COATING_COMPLETED" },
                { label: "Stripping Completed", value: "STRIPPING_COMPLETED" },
                { label: "Liquid Manufacturing Completed", value: "LIQUID_MANUFACTURING_COMPLETED" },
                { label: "Liquid Filling Completed", value: "LIQUID_FILLING_COMPLETED" },
                { label: "Liquid Sealing Completed", value: "LIQUID_SEALING_COMPLETED" },
                { label: "Liquid Labelling Completed", value: "LIQUID_LABELLING_COMPLETED" },
                { label: "Dry Syp Mixing Completed", value: "DRY_SYP_MIXING_COMPLETED" },
                { label: "Dry Syp Filling Completed", value: "DRY_SYP_FILLING_COMPLETED" },
                { label: "Dry Syp Sealing Completed", value: "DRY_SYP_SEALING_COMPLETED" },
                { label: "Dry Syp Labelling Completed", value: "DRY_SYP_LABELLING_COMPLETED" },
                { label: "Goods Packed and Ready To Dispatch", value: "READY_TO_DISPATCH" },
                { label: "Goods Dispatched", value: "GOODS_DISPATCHED" }
            ]

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Update Production Stage</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Order Details</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChange.bind(this)} options={Orders} id="order" name="order">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Product Stage</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeStage.bind(this)} options={Stages} id="stage" name="stage">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container" id="packingMaterialStockId">

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Stage Updated By</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} value={ jsonObject2.name } type="text" id="updatedBy" name="updatedBy"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Stage Updated At</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={!isPurchaseAdmin} onChange={event => { state.updatedAt = event.target.value; }} type="datetime-local" id="updatedAt" name="updatedAt"></input>
                        </div>

                    </div>

                    <button class="button" onClick={onUpload}>ADD</button>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>


                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>


                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}