import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { data } from 'jquery';
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function ViewProductionStagesForOrder() {

    const [post, setPost] = React.useState(null);
    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            const state = {
                orderId: ""
            };

            const handleChange = event => {
                state.orderId = event.value.orderId;
            };

            const handleAction = event => {

                const formData = new FormData();
                formData.append("orderId", state.orderId);

                document.getElementById("myTableBody").remove();

                var y = document.createElement("tbody");
                y.setAttribute("id", "myTableBody");
                document.getElementById("myTable").appendChild(y);

                var stockDict = {};

                var url = "https://cascamailsender.azurewebsites.net/getpackingmaterialstock?orderId=" + state.orderId;
                axios.get(url)
                    .then((response) => {
                        response.data.forEach((packingMaterialStock) => {

                            var key = packingMaterialStock.packingVariant + "_" + packingMaterialStock.packingType
                            if (stockDict[key] == null) {
                                stockDict[key] = packingMaterialStock.quantity;
                            }
                        });

                        axios.post("https://cascamailsender.azurewebsites.net/completeprocessfororder", formData)
                            .then((response) => {

                                response.data.forEach((productionProcess) => {


                                    var inputpartyname = document.getElementById("partyName");
                                    var inputproductname = document.getElementById("productName");
                                    var inputcomp = document.getElementById("composition");
                                    var inputpack = document.getElementById("packing");
                                    var inputmrp = document.getElementById("mrp");
                                    var inputquantity = document.getElementById("quantity");

                                    inputpartyname.value = productionProcess.partyName;
                                    inputproductname.value = productionProcess.productName;
                                    inputcomp.value = productionProcess.composition;
                                    inputpack.value = productionProcess.packing;
                                    inputmrp.value = productionProcess.mrp;
                                    inputquantity.value = productionProcess.quantity;

                                    var item = document.getElementById(productionProcess.id);
                                    if (item == null) {

                                        /*var y = document.createElement("tr");
                                        y.setAttribute("id", productionProcess.id);
                                        document.getElementById("myTableBody").appendChild(y);*/

                                        /*var y = document.createElement("tr");
                                        y.setAttribute("id", productionProcess.id);
                                        document.getElementById("myTable").appendChild(y);*/

                                        var y = document.createElement("tr");
                                        y.setAttribute("id", productionProcess.id);
                                        document.getElementById("myTableBody").appendChild(y);

                                        var z = document.createElement("td");
                                        z.setAttribute("class", "border_class");
                                        var t = document.createTextNode(productionProcess.stageCompletedOn);
                                        z.appendChild(t);
                                        y.appendChild(z);

                                        var z = document.createElement("td");
                                        z.setAttribute("class", "border_class");
                                        var t = document.createTextNode(productionProcess.stage);
                                        z.appendChild(t);

                                        //var productionStageText = productionProcess.stage;
                                        if (productionProcess.stage == "EXISTING_OUTER_BOX_STOCK_CHECK" || productionProcess.stage == "EXISTING_INNER_BOX_STOCK_CHECK" || productionProcess.stage == "EXISTING_FOIL_STOCK_CHECK" || productionProcess.stage == "EXISTING_BASE_FOIL_STOCK_CHECK" || productionProcess.stage == "EXISTING_LABEL_STOCK_CHECK"
                                            || productionProcess.stage == "OUTER_BOX_REC" || productionProcess.stage == "INNER_BOX_REC" || productionProcess.stage == "FOIL_REC" || productionProcess.stage == "BASE_FOIL_REC" || productionProcess.stage == "LABEL_REC") {
                                            var t = document.createElement("br");
                                            z.appendChild(t);

                                            var productionStageText = "";
                                            if (productionProcess.stage == "EXISTING_OUTER_BOX_STOCK_CHECK") {
                                                productionStageText = "OUTER BOXES: " + stockDict["OUTER_BOX_EXISTING"];
                                            } else if (productionProcess.stage == "EXISTING_INNER_BOX_STOCK_CHECK") {
                                                productionStageText = "INNER BOXES: " + stockDict["INNER_BOX_EXISTING"];
                                            } else if (productionProcess.stage == "EXISTING_FOIL_STOCK_CHECK") {
                                                productionStageText = "FOIL: " + stockDict["FOIL_EXISTING"] + " Kg";
                                            } else if (productionProcess.stage == "EXISTING_BASE_FOIL_STOCK_CHECK") {
                                                productionStageText = "BASE FOIL: " + stockDict["BASE_FOIL_EXISTING"] + " Kg";
                                            } else if (productionProcess.stage == "EXISTING_LABEL_STOCK_CHECK") {
                                                productionStageText = "LABEL: " + stockDict["LABEL_EXISTING"];
                                            } else if (productionProcess.stage == "OUTER_BOX_REC") {
                                                productionStageText = "OUTER BOXES: " + stockDict["OUTER_BOX_NEW"];
                                            } else if (productionProcess.stage == "INNER_BOX_REC") {
                                                productionStageText = "INNER BOXES: " + stockDict["INNER_BOX_NEW"];
                                            } else if (productionProcess.stage == "FOIL_REC") {
                                                productionStageText = "FOIL: " + stockDict["FOIL_NEW"] + " Kg";
                                            } else if (productionProcess.stage == "BASE_FOIL_REC") {
                                                productionStageText = "BASE FOIL: " + stockDict["BASE_FOIL_NEW"] + " Kg";
                                            } else if (productionProcess.stage == "LABEL_REC") {
                                                productionStageText = "LABEL: " + stockDict["LABEL_NEW"];
                                            }

                                            var t = document.createTextNode(productionStageText);
                                            z.appendChild(t);
                                        }
                                        y.appendChild(z);

                                        var z = document.createElement("td");
                                        z.setAttribute("class", "border_class");
                                        var t = document.createTextNode(productionProcess.updatedBy);
                                        z.appendChild(t);
                                        y.appendChild(z);
                                    }
                                });
                            });
                    });

                /*axios.post("https://cascamailsender.azurewebsites.net/completeprocessfororder", formData)
                    .then((response) => {

                        response.data.forEach((productionProcess) => {


                            var inputpartyname = document.getElementById("partyName");
                            var inputproductname = document.getElementById("productName");
                            var inputcomp = document.getElementById("composition");
                            var inputpack = document.getElementById("packing");
                            var inputmrp = document.getElementById("mrp");
                            var inputquantity = document.getElementById("quantity");

                            inputpartyname.value = productionProcess.partyName;
                            inputproductname.value = productionProcess.productName;
                            inputcomp.value = productionProcess.composition;
                            inputpack.value = productionProcess.packing;
                            inputmrp.value = productionProcess.mrp;
                            inputquantity.value = productionProcess.quantity;

                            var item = document.getElementById(productionProcess.id);
                            if (item == null) {                            

                                var y = document.createElement("tr");
                                y.setAttribute("id", productionProcess.id);
                                document.getElementById("myTableBody").appendChild(y);

                                var z = document.createElement("td");
                                z.setAttribute("class", "border_class");
                                var t = document.createTextNode(productionProcess.stageCompletedOn);
                                z.appendChild(t);
                                y.appendChild(z);

                                var z = document.createElement("td");
                                z.setAttribute("class", "border_class");
                                var t = document.createTextNode(productionProcess.stage);
                                z.appendChild(t);

                                if (productionProcess.stage == "EXISTING_OUTER_BOX_STOCK_CHECK" || productionProcess.stage == "EXISTING_INNER_BOX_STOCK_CHECK" || productionProcess.stage == "EXISTING_FOIL_STOCK_CHECK" || productionProcess.stage == "EXISTING_BASE_FOIL_STOCK_CHECK" || productionProcess.stage == "EXISTING_LABEL_STOCK_CHECK"
                                    || productionProcess.stage == "OUTER_BOX_REC" || productionProcess.stage == "INNER_BOX_REC" || productionProcess.stage == "FOIL_REC" || productionProcess.stage == "BASE_FOIL_REC" || productionProcess.stage == "LABEL_REC") {
                                    var t = document.createElement("br");
                                    z.appendChild(t);

                                    var productionStageText = "";
                                    if (productionProcess.stage == "EXISTING_OUTER_BOX_STOCK_CHECK") {
                                        productionStageText = "OUTER BOXES: " + stockDict["OUTER_BOX_EXISTING"];
                                    } else if (productionProcess.stage == "EXISTING_INNER_BOX_STOCK_CHECK") {
                                        productionStageText = "INNER BOXES: " + stockDict["INNER_BOX_EXISTING"];
                                    } else if (productionProcess.stage == "EXISTING_FOIL_STOCK_CHECK") {
                                        productionStageText = "FOIL: " + stockDict["FOIL_EXISTING"] + " Kg";
                                    } else if (productionProcess.stage == "EXISTING_BASE_FOIL_STOCK_CHECK") {
                                        productionStageText = "BASE FOIL: " + stockDict["BASE_FOIL_EXISTING"] + " Kg";
                                    } else if (productionProcess.stage == "EXISTING_LABEL_STOCK_CHECK") {
                                        productionStageText = "LABEL: " + stockDict["LABEL_EXISTING"];
                                    } else if (productionProcess.stage == "OUTER_BOX_REC") {
                                        productionStageText = "OUTER BOXES: " + stockDict["OUTER_BOX_NEW"];
                                    } else if (productionProcess.stage == "INNER_BOX_REC") {
                                        productionStageText = "INNER BOXES: " + stockDict["INNER_BOX_NEW"];
                                    } else if (productionProcess.stage == "FOIL_REC") {
                                        productionStageText = "FOIL: " + stockDict["FOIL_NEW"] + " Kg";
                                    } else if (productionProcess.stage == "BASE_FOIL_REC") {
                                        productionStageText = "BASE FOIL: " + stockDict["BASE_FOIL_NEW"] + " Kg";
                                    } else if (productionProcess.stage == "LABEL_REC") {
                                        productionStageText = "LABEL: " + stockDict["LABEL_NEW"];
                                    }

                                    var t = document.createTextNode(productionStageText);
                                    z.appendChild(t);
                                }
                                y.appendChild(z);

                                var z = document.createElement("td");
                                z.setAttribute("class", "border_class");
                                var t = document.createTextNode(productionProcess.updatedBy);
                                z.appendChild(t);
                                y.appendChild(z);
                            }
                        });
                    });*/
            }


            const Orders = [];

            fetch('https://cascamailsender.azurewebsites.net/allproductionorders', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost(data));

            if (!post) return null;

            var postModified = post.replace(/'/g, '"');
            let jsonData = JSON.parse(JSON.stringify(postModified));
            let jsonObject = JSON.parse(jsonData);
            jsonObject.forEach(object => {
                Orders.push({ label: "(" + object.orderId + ") " + object.productName + ", " + object.packing + ", MRP: " + object.mrp, value: object });
            })

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>View Production Stages For an Order</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Orders</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChange.bind(this)} options={Orders} id="orders" name="orders">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <button class="button" onClick={handleAction}>SUBMIT</button>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Party Name</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="partyName" name="partyName"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Product Name</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="productName" name="productName"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Composition</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="composition" name="composition"></input>
                        </div>

                    </div>


                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Packing</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="packing" name="packing"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>MRP</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="mrp" name="mrp"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Quantity</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="quantity" name="quantity"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Production Stage Details</h4>
                        </div>

                        <div class="flex-child green">
                            <table class="border_class" id="myTable">
                                <thead>
                                    <tr>
                                        <td class="border_class">Stage Updated At</td>
                                        <td class="border_class">Stage</td>
                                        <td class="border_class">Updated By</td>
                                    </tr>
                                    <tbody id="myTableBody">
                                    </tbody>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>


                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>


                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}