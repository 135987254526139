import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function AddProductionProcess() {

    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2 style={{ color: '#047BD5' }}>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            var isPurchaseAdmin = jsonObject2.userRoles != null && (jsonObject2.userRoles == 'administrator' || jsonObject2.userRoles == 'purchaseAdministrator');

            const state = {
                partyName: "",
                productName: "",
                composition: "",
                packing: "",
                mrp: "",
                quantity: "",
                stage: "",
                updatedBy: jsonObject2.name,
                updatedAt: null
            };

            const onUpload = () => {

                // Create an object of formData
                const formData = new FormData();

                formData.append("partyName", state.partyName);
                formData.append("productName", state.productName);
                formData.append("composition", state.composition);
                formData.append("packing", state.packing);
                formData.append("mrp", state.mrp);
                formData.append("quantity", state.quantity);
                formData.append("stage", "ORDER_REC");
                formData.append("updatedBy", state.updatedBy);

                if (state.updatedAt != null && state.updatedAt != '') {
                    formData.append("updatedAt", new Date(state.updatedAt));
                }

                axios.post("https://cascamailsender.azurewebsites.net/addproductionprocessstage", formData)
                    .then((response) => {
                        if (response.data == "STAGE_SAVED") {
                            alert("Stage Saved")
                        }
                    });
            };

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Add Production Order</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Party Name</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.partyName = event.target.value; }} type="text" id="partyName" name="partyName"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Product Name</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.productName = event.target.value; }} type="text" id="productName" name="productName"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Composition</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.composition = event.target.value; }} type="text" id="composition" name="composition"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Packing</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.packing = event.target.value; }} type="text" id="packing" name="packing"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>MRP</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.mrp = event.target.value; }} type="text" id="mrp" name="mrp"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Quantity</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.quantity = event.target.value; }} type="text" id="quantity" name="quantity"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Stage Updated By</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} value={jsonObject2.name} type="text" id="updatedBy" name="updatedBy"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Stage Updated At</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={!isPurchaseAdmin} onChange={event => { state.updatedAt = event.target.value; }} type="datetime-local" id="updatedAt" name="updatedAt"></input>
                        </div>

                    </div>

                    <button class="button" onClick={onUpload}>ADD</button>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>


                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>


                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}