import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { data } from 'jquery';
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function PendingProductionOrders() {


    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            const state = {
                order: "",
                time: "",
                stage: "ALL",
                updatedBy: jsonObject2.name
            };

            const handleMarkStage = (orderId) => {

                const formData = new FormData();

                formData.append("orderId", orderId);
                formData.append("stage", "GOODS_DISPATCHED");
                formData.append("updatedBy", state.updatedBy);

                axios.post("https://cascamailsender.azurewebsites.net/addproductionprocessstage", formData)
                    .then((response) => {
                        if (response.data == "STAGE_SAVED") {
                            alert("Stage Saved")
                        }
                    });
            }

            const handleAction = event => {

                var url = "https://cascamailsender.azurewebsites.net/allproductionorders?stage=" + state.stage;

                if (state.order == "Pending_Orders_Time") {
                    url = "https://cascamailsender.azurewebsites.net/pendingproductionorders?days=" + state.time + "&stage=" + state.stage;
                }

                document.getElementById("myTableBody").remove();

                var y = document.createElement("tbody");
                y.setAttribute("id", "myTableBody");
                document.getElementById("myTable").appendChild(y);

                axios.get(url)
                    .then((response) => {

                        response.data.forEach((productionProcess) => {

                            var item = document.getElementById(productionProcess.id);
                            if (item == null) {

                                /*var y = document.createElement("tr");
                                y.setAttribute("id", productionProcess.id);
                                document.getElementById("myTableBody").appendChild(y);*/

                                /*var y = document.createElement("tr");
                                y.setAttribute("id", productionProcess.id);
                                document.getElementById("myTable").appendChild(y);*/

                                var y = document.createElement("tr");
                                y.setAttribute("id", productionProcess.id);
                                document.getElementById("myTableBody").appendChild(y);

                                var z = document.createElement("td");
                                z.setAttribute("class", "border_class");
                                var t = document.createTextNode(productionProcess.partyName);
                                z.appendChild(t);
                                y.appendChild(z);

                                var z = document.createElement("td");
                                z.setAttribute("class", "border_class");
                                var t = document.createTextNode(productionProcess.productName);
                                z.appendChild(t);
                                y.appendChild(z);

                                var z = document.createElement("td");
                                z.setAttribute("class", "border_class");
                                var t = document.createTextNode(productionProcess.composition);
                                z.appendChild(t);
                                y.appendChild(z);

                                var z = document.createElement("td");
                                z.setAttribute("class", "border_class");
                                var t = document.createTextNode(productionProcess.stageCompletedOn);
                                z.appendChild(t);
                                y.appendChild(z);

                                var z = document.createElement("td");
                                z.setAttribute("class", "border_class");
                                var t = document.createTextNode(productionProcess.stage);
                                z.appendChild(t);
                                y.appendChild(z);

                                var z = document.createElement("td");
                                z.setAttribute("class", "border_class");
                                var t = document.createTextNode(productionProcess.updatedBy);
                                z.appendChild(t);
                                y.appendChild(z);


                                var z = document.createElement("td");
                                z.setAttribute("class", "border_class");
                                var t = document.createElement("button");
                                t.setAttribute("class", "button");
                                t.textContent = "Mark Dispatched";

                                t.addEventListener('click', function () {
                                    handleMarkStage(productionProcess.orderId);
                                });


                                z.appendChild(t);
                                y.appendChild(z);
                            }
                        });
                    });
            }

            const Stages = [
                { label: "All Stages", value: "ALL" },
                { label: "Order Received", value: "ORDER_REC" },
                { label: "Design Approved By Party", value: "DESIGN_APPROVAL_BY_PARTY" },
                { label: "Address Proof Submitted By Party", value: "ADDRESS_PROOF_DOC_SUBMISSION" },
                { label: "Design Approved By Production Team", value: "DESIGN_APPROVAL_BY_CASCA" },
                { label: "Existing Outer Box Stock Check", value: "EXISTING_OUTER_BOX_STOCK_CHECK" },
                { label: "Existing Inner Box Stock Check", value: "EXISTING_INNER_BOX_STOCK_CHECK" },
                { label: "Existing Foil Stock Check", value: "EXISTING_FOIL_STOCK_CHECK" },
                { label: "Existing Base Foil Stock Check", value: "EXISTING_BASE_FOIL_STOCK_CHECK" },
                { label: "Existing Label Stock Check", value: "EXISTING_LABEL_STOCK_CHECK" },
                { label: "Outer Box Sent For Printing", value: "OUTER_BOX_SENT_FOR_PRINTING" },
                { label: "Inner Box Sent For Printing", value: "INNER_BOX_SENT_FOR_PRINTING" },
                { label: "Foil Sent For Printing", value: "FOIL_SENT_FOR_PRINTING" },
                { label: "Base Foil Sent For Printing", value: "BASE_FOIL_SENT_FOR_PRINTING" },
                { label: "Label Sent For Printing", value: "LABEL_SENT_FOR_PRINTING" },
                { label: "Bottle Order Placed", value: "BOTTLE_ORDER_PLACED" },
                { label: "Pump Order Placed", value: "PUMP_ORDER_PLACED" },
                { label: "Order Sent to Production", value: "ORDER_SENT_TO_PRODUCTION" },
                { label: "Outer Box Received", value: "OUTER_BOX_REC" },
                { label: "Inner Box Received", value: "INNER_BOX_REC" },
                { label: "Foil Received", value: "FOIL_REC" },
                { label: "Base Foil Received", value: "BASE_FOIL_REC" },
                { label: "Label Received", value: "LABEL_REC" },
                { label: "Bottle Received", value: "BOTTLE_REC" },
                { label: "Pump Received", value: "PUMP_REC" },
                { label: "Raw Material Order Placed", value: "RAW_MATERIAL_ORDER_PLACED" },
                { label: "Raw Material Received", value: "RAW_MATERIAL_ORDER_REC" },
                { label: "Capsules Filling Completed", value: "CAPSULE_FILLING_COMPLETED" },
                { label: "Granulation Completed", value: "GRANULATION_COMPLETED" },
                { label: "Punching Completed", value: "PUNCHING_COMPLETED" },
                { label: "Coating Completed", value: "COATING_COMPLETED" },
                { label: "Stripping Completed", value: "STRIPPING_COMPLETED" },
                { label: "Liquid Manufacturing Completed", value: "LIQUID_MANUFACTURING_COMPLETED" },
                { label: "Liquid Filling Completed", value: "LIQUID_FILLING_COMPLETED" },
                { label: "Liquid Sealing Completed", value: "LIQUID_SEALING_COMPLETED" },
                { label: "Liquid Labelling Completed", value: "LIQUID_LABELLING_COMPLETED" },
                { label: "Dry Syp Mixing Completed", value: "DRY_SYP_MIXING_COMPLETED" },
                { label: "Dry Syp Filling Completed", value: "DRY_SYP_FILLING_COMPLETED" },
                { label: "Dry Syp Sealing Completed", value: "DRY_SYP_SEALING_COMPLETED" },
                { label: "Dry Syp Labelling Completed", value: "DRY_SYP_LABELLING_COMPLETED" },
                { label: "Goods Packed and Ready To Dispatch", value: "READY_TO_DISPATCH" },
                { label: "Goods Dispatched", value: "GOODS_DISPATCHED" }
            ]

            const OrderType = [
                { label: "All Pending Orders", value: "All_Pending_Orders" },
                { label: "Pending Orders for a time period", value: "Pending_Orders_Time" }
            ]

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Show Pending Orders</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Pending Order Type</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={event => { state.order = event.value; }} options={OrderType} id="orders" name="orders">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Stage</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={event => { state.stage = event.value; }} options={Stages} id="stage" name="stage">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Time Period in Days</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.time = event.target.value; }} type="text" id="timeperiod" name="timeperiod"></input>
                        </div>

                    </div>


                    <button class="button" onClick={handleAction}>SUBMIT</button>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Production Stage Details</h4>
                        </div>

                        <div class="flex-child green">
                            <table class="border_class" id="myTable">
                                <thead>
                                    <tr>
                                        <td class="border_class">Party Name</td>
                                        <td class="border_class">Product Name</td>
                                        <td class="border_class">Composition</td>
                                        <td class="border_class">Stage Updated At</td>
                                        <td class="border_class">Current Stage</td>
                                        <td class="border_class">Updated By</td>
                                        <td class="border_class">Update Stage</td>
                                    </tr>
                                    <tbody id="myTableBody">
                                    </tbody>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>


                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>


                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}