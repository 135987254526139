import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import background from "../background.jpg";
import { Redirect } from 'react-router';


export function ModifyRawMaterial() {

    const [post, setPost] = React.useState(null);
    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2 style={{ color: '#047BD5' }}>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);
            if (jsonObject2.userRoles != null && jsonObject2.userRoles == 'administrator') {
                const state = {
                    rmName: "",
                    rmPrice: "",
                    rmId: ""
                };

                const handleChange = event => {
                    // Update the state
                    var inputrmname = document.getElementById("rmName");
                    var inputrmprice = document.getElementById("rmPrice");                   

                    state.rmName = event.value.name;
                    state.rmPrice = event.value.price;
                    state.rmId = event.value.id;

                    inputrmname.value = event.value.name;
                    inputrmprice.value = event.value.price;               
                };

                const onUpload = () => {

                    const formData = new FormData();
                    formData.append("rmId", state.rmId);
                    formData.append("rmName", state.rmName);
                    formData.append("rmPrice", state.rmPrice);
                    
                    axios.post("https://cascamailsender.azurewebsites.net/modifyrawmaterial", formData)
                        .then((response) => {
                            alert(response.data)
                        });

                };

                const RawMaterials = [];

                fetch('https://cascamailsender.azurewebsites.net/getallrawmaterials', { mode: 'cors' })
                    .then(response => response.text())
                    .then(data => setPost(data));

                if (!post) return null;

                var postModified = post.replace(/'/g, '"');
                let jsonData = JSON.parse(JSON.stringify(postModified));
                let jsonObject = JSON.parse(jsonData);
                jsonObject.forEach(object => {
                    RawMaterials.push({ label: object.name, value: object });
                })

                return (
                    <div style={{
                        backgroundImage: `url(${background})`,
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "fixed",
                        backgroundSize: "cover"
                    }} className="App">
                        <h2 style={{ color: '#047BD5' }}>Modify Raw Material</h2>                      

                        <div class="flex-container">

                            <div class="flex-child magenta">
                                <h4>Existing Raw Material</h4>
                            </div>

                            <div class="flex-child green">
                                <div className="row">
                                    <div className="col-md-6">
                                        <Select onChange={handleChange.bind(this)} options={RawMaterials} id="rawmaterial" name="rawmaterial">
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex-container">

                            <div class="flex-child magenta">
                                <h4>RM Name</h4>
                            </div>

                            <div class="flex-child green">
                                <input onChange={event => { state.rmName = event.target.value; }} type="text" id="rmName" name="rmName"></input>
                            </div>

                        </div>

                        <div class="flex-container">

                            <div class="flex-child magenta">
                                <h4>RM Price</h4>
                            </div>

                            <div class="flex-child green">
                                <input onChange={event => { state.rmPrice = event.target.value; }} type="number" step="0.01" id="rmPrice" name="rmPrice"></input>
                            </div>

                        </div>

                        <button class="button" onClick={onUpload}>MODIFY</button>

                    </div>
                );
            } else {
                return (
                    <div style={{
                        backgroundImage: `url(${background})`,
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "fixed",
                        backgroundSize: "cover"
                    }} className="App">
                        <h3 style={{ color: '#047BD5' }}>You are not authorized to perform this operation</h3>
                    </div>
                );
			}
        } else {
            return (
                <div>
                    <Router>
                        <Switch>


                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>


                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}