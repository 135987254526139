import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { data } from 'jquery';
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function ModifyProductionProcess() {

    const [post, setPost] = React.useState(null);
    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            const state = {
                orderId: "",
                prevProductName: "",
                prevComposition: "",
                prevPacking: "",
                prevMrp: "",
                prevQuantity: "",
                newProductName: "",
                newComposition: "",
                newPacking: "",
                newMrp: "",
                newQuantity: "",
                updatedBy: jsonObject2.name
            };

            const handleChange = event => {
                state.orderId = event.value.orderId;
            };

            const handleAction = event => {

                const formData = new FormData();
                formData.append("orderId", state.orderId);

                axios.post("https://cascamailsender.azurewebsites.net/completeprocessfororder", formData)
                    .then((response) => {

                        response.data.forEach((productionProcess) => {

                            var inputproductname = document.getElementById("productName");
                            var inputcomp = document.getElementById("composition");
                            var inputpack = document.getElementById("packing");
                            var inputmrp = document.getElementById("mrp");
                            var inputquantity = document.getElementById("quantity");

                            inputproductname.value = productionProcess.productName;
                            inputcomp.value = productionProcess.composition;
                            inputpack.value = productionProcess.packing;
                            inputmrp.value = productionProcess.mrp;
                            inputquantity.value = productionProcess.quantity;

                            state.prevProductName = productionProcess.productName;
                            state.prevComposition = productionProcess.composition;
                            state.prevPacking = productionProcess.packing;
                            state.prevMrp = productionProcess.mrp;
                            state.prevQuantity = productionProcess.quantity;

                            state.newProductName = productionProcess.productName;
                            state.newComposition = productionProcess.composition;
                            state.newPacking = productionProcess.packing;
                            state.newMrp = productionProcess.mrp;
                            state.newQuantity = productionProcess.quantity;
                            
                        });
                    });
            }

            const modifyAction = event => {

                if (state.prevProductName == state.newProductName
                    && state.prevComposition == state.newComposition
                    && state.prevPacking == state.newPacking
                    && state.prevMrp == state.newMrp
                    && state.prevQuantity == state.newQuantity) {
                    alert("All the fields are same!");
                    return;
				}

                const formData = new FormData();
                formData.append("prevOrderId", state.orderId);
                formData.append("prevProductName", state.prevProductName);
                formData.append("prevComposition", state.prevComposition);
                formData.append("prevPacking", state.prevPacking);
                formData.append("prevMrp", state.prevMrp);
                formData.append("prevQuantity", state.prevQuantity);
                formData.append("newProductName", state.newProductName);
                formData.append("newComposition", state.newComposition);
                formData.append("newPacking", state.newPacking);
                formData.append("newMrp", state.newMrp);
                formData.append("newQuantity", state.newQuantity);
                formData.append("updatedBy", state.updatedBy);

                axios.post("https://cascamailsender.azurewebsites.net/addproductionprocesschangeevent", formData)
                    .then((response) => {
                        alert(response.data)
                    });
            }

            const Orders = [];

            fetch('https://cascamailsender.azurewebsites.net/allproductionorders', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost(data));

            if (!post) return null;

            var postModified = post.replace(/'/g, '"');
            let jsonData = JSON.parse(JSON.stringify(postModified));
            let jsonObject = JSON.parse(jsonData);
            jsonObject.forEach(object => {
                Orders.push({ label: "(" + object.orderId + ") " + object.productName + ", " + object.packing + ", MRP: " + object.mrp, value: object });
            })

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Modify Production Order</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Orders</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChange.bind(this)} options={Orders} id="orders" name="orders">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <button class="button" onClick={handleAction}>SUBMIT</button>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Product Name</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newProductName = event.target.value; }} type="text" id="productName" name="productName"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Composition</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newComposition = event.target.value; }} type="text" id="composition" name="composition"></input>
                        </div>

                    </div>


                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Packing</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newPacking = event.target.value; }} type="text" id="packing" name="packing"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>MRP</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newMrp = event.target.value; }} type="text" id="mrp" name="mrp"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Quantity</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newQuantity = event.target.value; }} type="text" id="quantity" name="quantity"></input>
                        </div>

                    </div>

                    <button class="button" onClick={modifyAction}>MODIFY</button>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>


                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>


                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}